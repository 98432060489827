
var textBox = document.querySelector(".template--slide .textBox");
var imageBox = document.querySelector(".template--slide .imageBox");

let sectionHeadline= document.querySelector("h2");
let textBoxText= document.querySelector(".textBox--text");

var slides = document.querySelectorAll(".slide");

var templateTrenner = document.querySelector(".template--trenner");
var trenner = document.querySelectorAll(".trenner");

var click;

var wrapper = document.querySelector(".wrapper");


var intro = document.querySelector(".intro");

var counterAnimation = 0;
var keyPressed = false;
var slideNumber = 20;

let moveDirection;
var timeouts = [];

var timeBar = document.querySelector(".timebarColor");

let scrollButtonDown = document.querySelector(".scrolldownButton--down");
let scrollButtonUp = document.querySelector(".scrolldownButton--up");

scrollButtonUp.style.display ="none";




if('scrollRestoration' in history) {
  history.scrollRestoration = 'manual';
}

window.addEventListener('DOMContentLoaded', (event) => {

  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  document.querySelector(".fakeScroll").style.height = 1200 * slideNumber + 10+ "px";

  console.log("scroll top");
  setBodyScrollTop(0);
  counterAnimation = 0;
  contextAnimation = 0; 

  initScroll();

  window.addEventListener("keyup", onWindowKeyUp);
  window.addEventListener("keydown", onWindowKeyDown);
  scrollButtonDown.addEventListener("click", onButtonDownClick);
  scrollButtonUp.addEventListener("click", onButtonUpClick);
});

window.addEventListener('beforeunload', (event) => {
  setBodyScrollTop(0);
});

function initScroll(){
  window.removeEventListener("scroll", onWindowScroll);
  window.addEventListener("scroll", onWindowScroll);
}

function getBodyScrollTop() { 
  const el = document.scrollingElement || document.documentElement;
  return el.scrollTop;
}

function setBodyScrollTop(value) { 
  const el = document.scrollingElement || document.documentElement;
  el.scrollTop = value;
  return el.scrollTop;
}

function getScrollStepHeight() { 
  return 800;
}



function onWindowScroll() {
  if (keyPressed) return;
  var contextAnimation = Math.floor(getBodyScrollTop() / Math.floor(getScrollStepHeight() * 1.5));

  // console.log("contextAnimation: "+ contextAnimation + " counterAnimation: " + counterAnimation);

  if (contextAnimation>counterAnimation) {
    moveDirection = "down";
  }else{
    moveDirection = "up";
  }
  console.log(moveDirection);


  if (counterAnimation === contextAnimation) return;
  counterAnimation = contextAnimation;    

  clearAllTimeouts();
  animationList();
  changeSlide();
    
}


function onButtonDownClick(){
  clearAllTimeouts();
  moveDirection ="down";
  counterAnimation++;
  setBodyScrollTop(1200*counterAnimation)
  clearAllTimeouts();
  animationList();
  changeSlide();
  click = false;


  scrollButtonDown.classList.add("buttonOnClick");

  setTimeout(function(){ 
    scrollButtonDown.classList.remove("buttonOnClick");
  }, 100);
  
}

function onButtonUpClick(){
  clearAllTimeouts();
  moveDirection ="up";
  counterAnimation--;
  setBodyScrollTop(1200*counterAnimation)
  clearAllTimeouts();
  animationList();
  changeSlide();
  click = false;
  scrollButtonUp.classList.add("buttonOnClick");
  setTimeout(function(){ 
    scrollButtonUp .classList.remove("buttonOnClick");
  }, 100);
  
}

function onWindowKeyDown(e){
  keyPressed = true;

  switch (e.keyCode) {
    case 38: 
      e.preventDefault();
      break;

    case 40:
      e.preventDefault();
      break;
  }

}

function onWindowKeyUp(e){
  keyPressed = false;

  switch (e.keyCode) {
    case 38: // up
      moveToSlide(Math.max(0, counterAnimation-1));
      e.preventDefault();
      break;


    case 40: // down
      moveToSlide(Math.min(slideNumber, counterAnimation+1));
      e.preventDefault();
      break;
  }
  
}

function moveToSlide(idx) {
  setBodyScrollTop(Math.floor(getScrollStepHeight() * 1.5) * idx);
}

function allFadeOutIn(switchSection){
  console.log("hallo")
  
  addTimeout(function(){
    textBox.classList.add("fadeOut");
    imageBox.classList.add("fadeOut");
  }, 0);

  addTimeout(function(){
    imageBox.classList.remove("fadeOut");
    imageBox.classList.add("fadeIn");
    textBox.classList.remove("fadeOut");
    textBox.classList.add("fadeIn");
  }, 100);
}

function textFadeOutIn(){
}


function changeSlide(){

  let correctClassName;
  timeBar.style.height = (100 / (slideNumber -2))* counterAnimation +"%";
  
  if (counterAnimation >= 1 && counterAnimation < slideNumber) {

      correctClassName = counterAnimation-1;
      textBox.innerHTML = slides[counterAnimation].querySelector(".textBox").innerHTML;
      imageBox.className = "imageBox imageBox--" + (correctClassName);
      imageBox.innerHTML = slides[counterAnimation].querySelector(".imageBox").innerHTML;
      textBox.className = "textBox textBox--" + (correctClassName);

  }
}


function slidePosition(sectionNumberInput,fadeInEffectInput,switchSection){
  var sectionSlides = document.querySelectorAll(".section--"+sectionNumberInput+ " .slide");
  sectionNumber = sectionNumberInput;
  fadeInEffect = fadeInEffectInput;
  contentFadeIn(fadeInEffect,switchSection);


  var slide = slides[counterAnimation];
  
 
  for (let i = 0; i <= sectionSlides.length; i++) {

    if (sectionSlides[i] == slide) {
      if(i === 0){

        slideFirst();
      }

      if(i === sectionSlides.length-1){
        slideLast(sectionNumber)
      } 
    }
  }
}


function slideFirst(){
  addTimeout(function(){ 
    wrapper.className = "wrapper wrapper--"+sectionNumber;
  }, 300);
}

function slideLast(sectionNumber){
  wrapper.className = "wrapper wrapper--" + sectionNumber;
}

function contentFadeIn(fadeInEffectInput,switchSection){
  if (fadeInEffectInput==="all") {
    allFadeOutIn(switchSection)
  }
  if (fadeInEffectInput==="text") {
    textFadeOutIn(switchSection)
  }if(fadeInEffectInput==="none"){
  }
}

function colorGrid(){
  // Section 3: Slides 3 
  addTimeout(function(){ 
    console.log("test grid");
    let gridBoxes = document.querySelectorAll(".wrapper--3 .imageBox--7 .gridBox");
    console.log(gridBoxes);
    for (i = 0; i < gridBoxes.length; i++) { 
      console.log("test grid element");
      let gridBox = gridBoxes[i];
      let mouseLeaveDelay = null;
    }
  }, 100); 
}

function iconAnimation(){
  console.log("ticon");
  addTimeout(function(){ 
    textBox.classList.add("fadeIn");
    imageBox.classList.add("fadeIn");
    var icons = document.querySelectorAll(".wrapper--3 .imageBox--8 img");

    var exists = [],
    randomNumber;
    for(var l=0;l < 15;l++) {
      do {
          randomNumber = Math.floor(Math.random()*15);  
      } while (exists[randomNumber]);
      exists[randomNumber] = true;
      icons[randomNumber].style.animationDelay = 0.3 + l*0.15 +"s";
    }

    for (let i = 0; i < icons.length; i++) {
      let mouseLeaveDelay = null;

      icons[i].addEventListener('mouseleave', function(){
        mouseLeaveDelay = setTimeout(() => {
          icons[i].classList.remove("iconHover");
        }, 500)
      });

      icons[i].addEventListener('mouseenter', function(){
        console.log("hover");
        icons[i].classList.add("iconHover");
        clearTimeout(mouseLeaveDelay);
      });
    }
  }, 200); 
}

function typping(){

  addTimeout(function(){ 
    let hightTextBox = document.querySelector(".imageBox__textBox").offsetHeight;
    document.querySelector(".wrapper--3 .imageBox--6 h4").innerHTML="";
    document.querySelector(".wrapper--3 .imageBox--6 p").innerHTML="";

    var iHeadline = 0;
    var iParagraph = 0;
    var txtHeadline = 'Fidèle au passé ou novatrice, la flèche de Notre-Dame échauffe les esprits';
    var txtParagraph = "Le débat enflamme les esprits depuis qu'un concours d'architectes a été annoncé. Le débat enflamme les esprits depuis qu'un concours d'architectes a été annoncé.";
    var finishedFirstLine = false;

    document.querySelector(".imageBox__textBox").style.height = hightTextBox + "px";

    function typeWriterHeadline() {
      if (iHeadline < txtHeadline.length) {
        document.querySelector(".wrapper--3 .imageBox--6 h4").innerHTML += txtHeadline.charAt(iHeadline);
        iHeadline++;
        addTimeout(typeWriterHeadline, 30);
      }
    }
    typeWriterHeadline();

    function typeWriterParagraph() {
      if (iParagraph < txtParagraph.length) {
        document.querySelector(".wrapper--3 .imageBox--6 p").innerHTML += txtParagraph.charAt(iParagraph);
        document.querySelector(".wrapper--3 .imageBox--6 p").classList.add("active")
        iParagraph++;          
        addTimeout( typeWriterParagraph, 20);
      }
    }
  
     addTimeout(typeWriterParagraph, 2600);


  }, 100);
}

function addTimeout(func, delay = 0) {
  let to = window.setTimeout( func, delay );
  timeouts.push(to);
}


function clearAllTimeouts() {
  for(let i = 0; i < timeouts.length; i++) {
    window.clearTimeout(timeouts[i]);
  }
  timeouts = [];
}

  
function animationList(){

  sectionNumber = 0;
  let switchSection=false;
  switch (counterAnimation) {
      
    case 0:
      //INTRO
      console.log("intro")
      sectionNumber = 0;
      switchSection=false;
      wrapper.className = "wrapper wrapper--1";
      intro.className = "slide intro";
      imageBox.innerHTML="";
      scrollButtonUp.style.display ="none";
     
      break;

    /* SECTION  1*/
    case 1:
      console.log("slide-1")
      switchSection=true;
      intro.className ="slide intro intro--fadeOut";
      document.querySelector(".intro--right").innerHTML ="<p>Un nouveau site web<br> et une nouvelle<br> identité pour<br> L’Orient-Le Jour<br><span>Design par <a href='https://datagif.fr/' target='_blank'>Datagif</a></span></p>";
      wrapper.className = "wrapper wrapper--1";
      sectionNumber = 1;
      allFadeOutIn(switchSection);
      scrollButtonUp.style.display ="block";
      break;
        

   
    case 2:  
    console.log("slide-2")
    switchSection=false;

      slidePosition(1,"all",switchSection); 
      break;
     
    case 3:
      console.log("slide-3")
      switchSection=false;

      slidePosition(1,"all",switchSection);
      break;

    /* SECTION 2*/
    case 4:
      console.log("slide-4")
      switchSection=true;

      slidePosition(1,"all",switchSection);
      break;
    
    case 5:
      switchSection=false;
      slidePosition(1,"all",switchSection);
      break;

    /* SECTION 3*/
    case 6:
      switchSection=true;
      slidePosition(3,"all",switchSection);
      allFadeOutIn();
      break;
      
    case 7:
      switchSection=false;
      console.log("typo "+counterAnimation+"class 6")
      slidePosition(3,"all",switchSection);
      typping();
      break;
        
    case 8:
      switchSection=false;

      slidePosition(3,"all",switchSection);
      colorGrid(); 
      break; 
    
    case 9:
      switchSection=false;
      iconAnimation();
      slidePosition(3,"all",switchSection);
      break;


    /* SECTION 4*/  
    case 10:
      switchSection=true;
      slidePosition(4,"all",switchSection);
      break;

    case 11:
      switchSection=false;
      slidePosition(4,"all",switchSection);
      break;

    case 12:
      switchSection=false;
      slidePosition(4,"all",switchSection);
      break;

    
    // /*SECTION 5*/ 
    case 13:
      switchSection=true;
      slidePosition(5,"all",switchSection);
      break;
      
    case 14:
      switchSection=false;
      slidePosition(5,"all",switchSection);
      break;

    case 15:
      switchSection=false;
      slidePosition(5,"all",switchSection);
      break;

    case 16:
      switchSection=true;
      slidePosition(6,"all",switchSection);
      break;

    case 17:
      console.log("slide last")
      switchSection=true;
      //sectionTitelFadeOutIn(switchSection)
      slidePosition(7,"all");
      intro.className ="slide intro end intro--fadeOut";
      scrollButtonDown.style.display ="block";
      
      
      break;

    case 18:
      console.log("end")
      switchSection=false;
      intro.className ="slide intro end";
      document.querySelector(".intro--right").innerHTML ="<a target='_blank' href='https://www.lorientlejour.com/'>Découvrir le nouveau site</a><a target='_blank' href='https://datagif.fr/'>En savoir plus sur Datagif</a>";
      scrollButtonDown.style.display ="none";
      break;

    
  }
}
